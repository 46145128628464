var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { WidgetOpenState, WidgetState } from './types';
import { isValidUrl } from './utils';
export var getWebChatURL = function (partnerConfig, isEmbed) {
    var url = partnerConfig.webChatUrl || process.env.WEB_CHAT_URL || 'https://widget.eddytravels.com';
    var updatedPartnerId = "" + (isEmbed ? 'embed-' : '') + (partnerConfig.partnerId || '');
    if (updatedPartnerId) {
        return url.replace('widget.', updatedPartnerId + ".widget.");
    }
    return url;
};
export var getWebChatUrlWithQuery = function (partnerConfig, isEmbed) {
    var _a, _b;
    var root = getWebChatURL(partnerConfig, isEmbed);
    var params = new URLSearchParams(window.location.search);
    params.append('widget_host', window.location.hostname);
    if (partnerConfig.updatedAt) {
        params.append('cb', Math.abs(Date.parse(partnerConfig.updatedAt)).toString());
    }
    if ((_a = partnerConfig.preview) === null || _a === void 0 ? void 0 : _a.deeplinkText) {
        params.append('send', partnerConfig.preview.deeplinkText);
    }
    if ((_b = partnerConfig.preview) === null || _b === void 0 ? void 0 : _b.deeplink) {
        params.append('dl', partnerConfig.preview.deeplink);
    }
    if (partnerConfig.defaultLanguageCode) {
        params.append('language', partnerConfig.defaultLanguageCode);
    }
    return root + "/?" + params.toString();
};
export var EDDY_TRAVELS_EVENT_NAME = '__EDDY_TRAVELS_CHAT_EVENT';
export var sendChatEvent = function (event) {
    var _a;
    (_a = document.getElementById('__EDDY_TRAVELS_CHAT_WIDGET')) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new CustomEvent(EDDY_TRAVELS_EVENT_NAME, { detail: event }));
};
export var onLinkClick = function (_a) {
    var iframe = _a.iframe, partnerConfig = _a.partnerConfig, onValidClick = _a.onValidClick, isEmbed = _a.isEmbed;
    return function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var linkTarget, href, url, dl, send;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    linkTarget = e.target;
                    while (linkTarget !== null) {
                        if (((_a = linkTarget === null || linkTarget === void 0 ? void 0 : linkTarget.tagName) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === 'A') {
                            break;
                        }
                        linkTarget = linkTarget.parentElement;
                    }
                    if (linkTarget === null) {
                        return [2 /*return*/];
                    }
                    href = linkTarget.getAttribute('href');
                    if (!href) {
                        return [2 /*return*/];
                    }
                    try {
                        url = new URL(href);
                    }
                    catch (_f) {
                        return [2 /*return*/];
                    }
                    if (url.host !== 'chat.eddytravels.com') {
                        return [2 /*return*/];
                    }
                    dl = ((_b = url === null || url === void 0 ? void 0 : url.searchParams) === null || _b === void 0 ? void 0 : _b.get('dl')) || undefined;
                    send = ((_c = url === null || url === void 0 ? void 0 : url.searchParams) === null || _c === void 0 ? void 0 : _c.get('send')) || undefined;
                    if (!dl && !send) {
                        return [2 /*return*/];
                    }
                    e.preventDefault();
                    if (!(typeof onValidClick === 'function')) return [3 /*break*/, 2];
                    return [4 /*yield*/, onValidClick({ dl: dl, send: send })];
                case 1:
                    _e.sent();
                    _e.label = 2;
                case 2:
                    (_d = iframe === null || iframe === void 0 ? void 0 : iframe.contentWindow) === null || _d === void 0 ? void 0 : _d.postMessage({
                        type: '__EDDY_TRAVELS_CHAT_DEEPLINK',
                        query: url.search,
                        parentQuery: window.location.search
                    }, getWebChatURL(partnerConfig, isEmbed));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var onMessage = function (setWidgetOpenState, setChatLoaded, widgetOpenState, partnerConfig
// eslint-disable-next-line max-params
) { return function (message) {
    var _a, _b, _c, _d, _e;
    if (((_a = message === null || message === void 0 ? void 0 : message.data) === null || _a === void 0 ? void 0 : _a.type) === '__EDDY_TRAVELS_CHAT_CLOSE') {
        setWidgetOpenState(WidgetOpenState.Closed);
    }
    else if (((_b = message === null || message === void 0 ? void 0 : message.data) === null || _b === void 0 ? void 0 : _b.type) === '__EDDY_TRAVELS_CHAT_OPEN' &&
        (widgetOpenState === WidgetOpenState.Open || widgetOpenState === WidgetOpenState.Initial)) {
        setWidgetOpenState(WidgetOpenState.Open);
    }
    else if (((_c = message === null || message === void 0 ? void 0 : message.data) === null || _c === void 0 ? void 0 : _c.type) === '__EDDY_TRAVELS_CHAT_LOAD_THEME_SUCCESS') {
        setChatLoaded(true);
    }
    else if (((_d = message === null || message === void 0 ? void 0 : message.data) === null || _d === void 0 ? void 0 : _d.type) === EDDY_TRAVELS_EVENT_NAME) {
        sendChatEvent(message.data.event);
    }
    else if (((_e = message === null || message === void 0 ? void 0 : message.data) === null || _e === void 0 ? void 0 : _e.type) === '__EDDY_TRAVELS_CHAT_READY') {
        initConfigLoad(partnerConfig);
    }
}; };
var bodyClassName = '__EDDY_TRAVELS_CHAT_IS_OPEN';
export var toggleBodyScroll = function (isVisible) {
    if (!isVisible) {
        var style = document.createElement('style');
        style.innerHTML = "." + bodyClassName + " { position: static !important; overflow: hidden !important; height: 100vh !important; }";
        document.body.appendChild(style);
        document.body.classList.add(bodyClassName);
        document.documentElement.classList.add(bodyClassName);
    }
    else {
        document.documentElement.classList.remove(bodyClassName);
        document.body.classList.remove(bodyClassName);
    }
};
var sanitizePartnerConfig = function (partnerConfig) {
    for (var i in partnerConfig) {
        if (typeof partnerConfig[i] === 'object') {
            partnerConfig[i] = sanitizePartnerConfig(partnerConfig[i]);
        }
        else if (i.toLocaleLowerCase().includes('url') && !isValidUrl(partnerConfig[i])) {
            delete partnerConfig[i];
        }
        else if (partnerConfig[i] === '') {
            delete partnerConfig[i];
        }
    }
    return partnerConfig;
};
export var initConfigLoad = function (partnerConfig) {
    var _a;
    var iframe = document.getElementById('__EDDY_TRAVELS_CHAT_IFRAME');
    (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({
        type: '__EDDY_TRAVELS_CHAT_LOAD_THEME',
        configParams: sanitizePartnerConfig(partnerConfig)
    }, getWebChatURL(partnerConfig));
    window.dispatchEvent(new Event('__ET_THEME_INITIALIZED'));
};
export var updateConfigLoad = function (partnerConfig, widgetState) {
    var _a;
    if (widgetState === void 0) { widgetState = WidgetState.Initialized; }
    if (widgetState > WidgetState.Initialized) {
        var iframe = document.getElementById('__EDDY_TRAVELS_CHAT_IFRAME');
        (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({
            type: '__EDDY_TRAVELS_CHAT_LOAD_THEME',
            configParams: sanitizePartnerConfig(partnerConfig)
        }, getWebChatURL(partnerConfig));
    }
    var event = new CustomEvent('__ET_THEME_UPDATE', {
        detail: {
            partnerConfig: sanitizePartnerConfig(partnerConfig)
        }
    });
    window.dispatchEvent(event);
};
export var showWidget = function (show) {
    var event = new CustomEvent('__ET_THEME_SHOW-HIDE', {
        detail: {
            show: show
        }
    });
    window.dispatchEvent(event);
};
if (typeof window !== 'undefined') {
    if (typeof window.__ET_API === 'undefined') {
        window.__ET_API = {};
    }
    // deprecated
    window.__ET_initTheme = updateConfigLoad;
    window.__ET_API.updateConfig = updateConfigLoad;
    window.__ET_API.showWidget = function () { return showWidget(true); };
    window.__ET_API.hideWidget = function () { return showWidget(false); };
}
