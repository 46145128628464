import React, { useEffect, useState } from 'react';
import { EDDY_TRAVELS_EVENT_NAME, getWebChatUrlWithQuery, onLinkClick, sendChatEvent } from '../eventHandlers';
export var EmbeddedWidget = function () {
    var partnerConfig = useState(window.__ET || '')[0];
    useEffect(function () {
        var iframe = document.getElementById('__EDDY_TRAVELS_CHAT_IFRAME');
        var clickHandler = onLinkClick({ iframe: iframe, partnerConfig: partnerConfig, isEmbed: true });
        var messageHandler = function (message) {
            var _a;
            if (((_a = message === null || message === void 0 ? void 0 : message.data) === null || _a === void 0 ? void 0 : _a.type) === EDDY_TRAVELS_EVENT_NAME) {
                sendChatEvent(message.data.event);
            }
        };
        var chatEventHandler = function (event) {
            if (typeof window.__ET_EVENT_HANDLER === 'function') {
                window.__ET_EVENT_HANDLER(event.detail);
            }
        };
        window.addEventListener('click', clickHandler);
        window.addEventListener('message', messageHandler);
        var widgetContainer = document.getElementById('__EDDY_TRAVELS_CHAT_WIDGET');
        widgetContainer === null || widgetContainer === void 0 ? void 0 : widgetContainer.addEventListener(EDDY_TRAVELS_EVENT_NAME, 
        // eslint-disable-next-line
        chatEventHandler);
        return function () {
            window.removeEventListener('click', clickHandler);
            window.removeEventListener('message', messageHandler);
            widgetContainer === null || widgetContainer === void 0 ? void 0 : widgetContainer.removeEventListener(EDDY_TRAVELS_EVENT_NAME, 
            // eslint-disable-next-line
            chatEventHandler);
        };
    }, []);
    return (React.createElement("iframe", { id: "__EDDY_TRAVELS_CHAT_IFRAME", src: getWebChatUrlWithQuery(partnerConfig, true), scrolling: "no", width: "100%", height: "100%", frameBorder: 0, style: { overflow: 'hidden', border: 'none', width: '100%', height: '100%' }, allow: "clipboard-write; web-share" }));
};
